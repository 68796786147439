import html2canvas from 'html2canvas';
import { useLocation } from 'react-router-dom';

export function generateRandomString(length: number): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

export async function getScreenshot(height: number) {
  const canvas = await html2canvas(document.body, {
    scale: 1,
    scrollX: 0,
    scrollY: 0,
    height: height,
    windowHeight: height,
    allowTaint: true,
    useCORS: true
  });
  const img_base64 = canvas.toDataURL('image/png');
  console.log('Screenshot taken');
  return img_base64;
}

export function useInstallerId(): string | null {
  // Read installer ID from query params in the URL.
  // If not defined, returns null.
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const installerId = query.get('installerid');
  return installerId;
}

export function useDevicesPresentParams(): object | null {
  // Read the devices present from query params in the URL.
  // If not defined, returns null.
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const devicesPresent: { [key: string]: boolean | null } = {};
  const deviceKeys = ['electric_radiator_present', 'heat_pump_present'];
  deviceKeys.forEach((key) => {
    let deviceValue = query.get(key);
    if (deviceValue !== null) {
      let lowercaseDeviceValue = deviceValue.toLowerCase();
      let booleanDeviceValue = lowercaseDeviceValue === 'true' ? true : false;
      devicesPresent[key] = booleanDeviceValue;
    }
  });
  return Object.keys(devicesPresent).length === 0 ? null : devicesPresent;
}

export function useIsDebugMode(): boolean {
  // Checks whether the debug mode is specificed in the query params in the URL.
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const debugValue = query.get('debug');
  const isDebug = debugValue !== null && debugValue.toLowerCase() === 'true';
  return isDebug;
}
