import { Typography } from '@mui/material';

const MessageVerifyEmail = ({ supportEmail }) => {
  return (
    <>
      <Typography variant="body1" align={'center'}>
        <h3>Veuillez valider votre email.</h3>
        <p style={{ maxWidth: '400px', margin: 'auto' }}>
          Nous venons de vous envoyer un email contenant un lien pour poursuivre l’enregistrement.
        </p>
      </Typography>
      <br />
      <br />
      <Typography variant="body2" align={'center'} color={'#606060'}>
        <i>
          Je n’ai pas reçu le mail ? <br />
          Regardez dans vos spams ou contactez nous à{' '}
          <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
        </i>
      </Typography>
    </>
  );
};
export default MessageVerifyEmail;
