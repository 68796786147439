import { useEffect } from 'react';
import { Box, Paper, Stack, Typography, Checkbox, FormControlLabel } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { CheckboxError } from '../util/checkbox';
import { required_message } from '../util/fields';
import PlaceAutocomplete from '../util/placeAutocomplete';
import ErrorMessage from '../util/errorMessage';
import HelpIcon from '@mui/icons-material/Help';
import * as Yup from 'yup';

export const linkyStepInitialValues = {
  firstName: '',
  lastName: '',
  address: '',
  postcode: '',
  city: '',
  checkboxEnedis: false,
  checkboxDemandResponse: false
};

export const linkyStepValidation = Yup.object({
  firstName: Yup.string().required(`${required_message} Prénom`),
  lastName: Yup.string().required(`${required_message} Nom`),
  address: Yup.string().required('Veuillez renseigner votre adresse complète'),
  city: Yup.string().required(),
  prm: Yup.string().matches(/^\d{14}$/, 'Le numéro PRM contient exactement 14 chiffres'),
  checkboxEnedis: Yup.bool().oneOf([true], 'Vous devez accepter les conditions'),
  checkboxDemandResponse: Yup.bool().oneOf([true], 'Vous devez accepter les conditions')
});

const enedisRteConsentLabel = (
  <p style={{ margin: '0px' }}>
    Je consens librement à ce qu'
    <a href="https://www.enedis.fr" target="_blank" rel="noopener noreferrer">
      ENEDIS
    </a>{' '}
    transmette les données de mon compteur Linky à Tilt Energy et{' '}
    <a href="https://www.rte-france.com" target="_blank" rel="noopener noreferrer">
      RTE
    </a>
    .
  </p>
);

const Step3Linky = ({
  formikErrors,
  formikTouched,
  validationSchema,
  providerSlug,
  includePRM,
  errorMessage
}) => {
  const { setFieldValue, setFieldTouched, values, handleChange } = useFormikContext();

  useEffect(() => {
    if (values.address || values.postcode || values.city) {
      setFieldTouched('address', true);
      setFieldTouched('postcode', true);
      setFieldTouched('city', true);
    }
  }, [values.address, values.postcode, values.city, setFieldTouched]);

  const handlePlaceSelect = (place) => {
    let postcode, city, streetNumber, streetName;
    place['address_components'].forEach((component) => {
      if (component.types.includes('postal_code')) {
        postcode = component.long_name;
      } else if (component.types.includes('locality')) {
        city = component.long_name;
      } else if (component.types.includes('street_number')) {
        streetNumber = component.long_name;
      } else if (component.types.includes('route')) {
        streetName = component.long_name;
      }
    });

    setFieldValue('postcode', postcode);
    setFieldValue('city', city);
    if (streetNumber !== null && streetNumber !== undefined) {
      setFieldValue('address', streetNumber + ' ' + streetName);
    } else {
      setFieldValue('address', streetName);
    }
    setFieldValue('streetNumber', streetNumber);
    setFieldValue('streetName', streetName);

    setFieldTouched('address', true);
    setFieldTouched('postcode', true);
    setFieldTouched('city', true);
  };

  const handlePlaceChange = () => {
    setFieldValue('postcode', '');
    setFieldValue('city', '');
    setFieldValue('address', '');
    setFieldValue('streetNumber', '');
    setFieldValue('streetName', '');

    setFieldTouched('address', true);
    setFieldTouched('postcode', true);
    setFieldTouched('city', true);
  };

  return (
    <>
      <div id="testElement">
        <Typography variant="h4" gutterBottom align={'left'} paddingLeft={1}>
          <b>Connectez votre Linky</b>
        </Typography>
        <Typography variant="body1" align={'left'} paddingLeft={1} paddingBottom={1}>
          Informations sur le <b>titulaire</b> du contrat d’électricité :
        </Typography>
        <Stack direction="row" paddingBottom={0.5}>
          <Field fullWidth name="firstName" component={TextField} label="Prénom*" />
          <Field fullWidth name="lastName" component={TextField} label="Nom*" />
        </Stack>

        <Stack
          direction="column"
          spacing={1}
          paddingBottom={0.5}
          marginLeft={'8px'}
          marginRight={'32px'}
        >
          <PlaceAutocomplete
            setSelectedPlace={handlePlaceSelect}
            handlePlaceChange={handlePlaceChange}
          />
          {formikTouched.address &&
            (formikErrors.address || formikErrors.postcode || formikErrors.city) && (
              <Typography color="error" variant="caption" marginTop={'3px !important'}>
                {formikErrors.address}
              </Typography>
            )}
        </Stack>

        {includePRM && (
          <>
            <Stack direction="row" paddingBottom={0}>
              <Field fullWidth name="prm" component={TextField} label="Numéro PRM (PDL)" />
            </Stack>
            <Typography variant="p" paddingLeft={1} sx={{ display: 'block' }}>
              <a
                href={`/find-prm`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#002160', fontWeight: 600, fontSize: '15px', display: 'block' }}
                className="link_tutorial"
              >
                <HelpIcon
                  style={{
                    fontSize: '18px',
                    verticalAlign: '-3px',
                    marginRight: '2px'
                  }}
                />
                Comment trouver mon numéro PRM (PDL) ?
              </a>
            </Typography>
          </>
        )}

        <Paper
          elevation={0}
          variant={'outlined'}
          style={{
            padding: '16px 16px 0px 16px',
            margin: '35px 8px 7px 8px',
            textAlign: 'left',
            backgroundColor: 'rgb(252 252 252)',
            color: '#363635'
          }}
        >
          <Typography variant="p" lineHeight={1.5}>
            <InfoIcon style={{ verticalAlign: '-5px', marginRight: '5px', fontSize: '24px' }} />
            Pour mener à bien le programme, nous utiliserons les données suivantes :
            <ul style={{ marginTop: '6px' }}>
              <li style={{ lineHeight: '1.5' }}>Courbe de consommation historique ;</li>
              <li style={{ lineHeight: '1.5' }}>Courbe de consommation journalière ;</li>
              <li style={{ lineHeight: '1.5' }}>Paramètres techniques de votre compteur.</li>
            </ul>
          </Typography>
        </Paper>

        <Box
          style={{
            padding: 10,
            paddingBottom: 3,
            margin: 0,
            textAlign: 'left'
          }}
        >
          <Box paddingTop={0.5}>
            <FormControlLabel
              control={<Checkbox checked={values.checkboxEnedis} />}
              label={enedisRteConsentLabel}
              name="checkboxEnedis"
              onChange={handleChange}
            />
            {formikErrors.checkboxEnedis && formikTouched.checkboxEnedis && (
              <CheckboxError>{formikErrors.checkboxEnedis}</CheckboxError>
            )}
          </Box>

          <Box paddingTop={2} paddingBottom={2.5}>
            <FormControlLabel
              control={<Checkbox checked={values.checkboxDemandResponse} />}
              label={
                <span>
                  J'autorise Tilt Energy à réaliser des effacements sur ma consommation et à{' '}
                  <a
                    href="https://www.services-rte.com/en/learn-more-about-our-services/participate-nebef-mechanism"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    les valoriser sur les marchés de l’énergie et les marchés de capacité
                  </a>
                  .
                </span>
              }
              name="checkboxDemandResponse"
              onChange={handleChange}
            />
            {/* <Field
            name="checkboxDemandResponse"
            type="checkbox"
            component={CheckboxWithLabel}
            Label={{
              label: (
                <span>
                  J'autorise Tilt Energy à réaliser des effacements sur ma consommation et à{' '}
                  <a
                    href="https://www.services-rte.com/en/learn-more-about-our-services/participate-nebef-mechanism"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    les valoriser sur les marchés de l’énergie et les marchés de capacité
                  </a>
                  .
                </span>
              )
            }}
          /> */}
            {formikErrors.checkboxDemandResponse && formikTouched.checkboxDemandResponse && (
              <CheckboxError>{formikErrors.checkboxDemandResponse}</CheckboxError>
            )}
          </Box>

          <Typography variant="p">
            Voir nos{' '}
            <a href={`/terms/${providerSlug}`} target="_blank" rel="noopener noreferrer">
              Conditions Générales
            </a>{' '}
            pour plus d'informations.
          </Typography>
        </Box>

        {errorMessage && (
          <div style={{ marginTop: '40px' }}>
            <ErrorMessage errorMessage={errorMessage} />
          </div>
        )}
      </div>
    </>
  );
};

export default Step3Linky;
