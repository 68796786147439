import { useState, useEffect, useRef } from 'react';
import { APIProvider, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useFormikContext } from 'formik';

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const Autocomplete = ({ onPlaceSelect, handlePlaceChange }) => {
  const { errors, touched, values, isSubmitting, setFieldTouched } = useFormikContext();
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const inputRef = useRef(null);
  const places = useMapsLibrary('places');

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      componentRestrictions: { country: 'fr' },
      fields: ['address_components', 'formatted_address'],
      types: ['address'] // Restricting to address types
    };

    const autocomplete = new places.Autocomplete(inputRef.current, options);
    setPlaceAutocomplete(autocomplete);
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener('place_changed', () => {
      const place = placeAutocomplete.getPlace();
      onPlaceSelect(place);
    });
  }, [onPlaceSelect, placeAutocomplete]);

  useEffect(() => {
    if (!errors.address) {
      inputRef.current.classList.remove('autocomplete-error');
      inputRef.current.classList.remove('autocomplete-submitting');
      inputRef.current.classList.add('autocomplete-noerror');
    }
    if (touched.address && errors.address) {
      inputRef.current.classList.remove('autocomplete-noerror');
      inputRef.current.classList.remove('autocomplete-submitting');
      inputRef.current.classList.add('autocomplete-error');
    }
  }, [errors, touched]);

  useEffect(() => {
    if (values.address && isSubmitting) {
      inputRef.current.disabled = 'true';
      if (inputRef.current.classList.contains('autocomplete-submitting')) {
        inputRef.current.classList.remove('autocomplete-submitting');
      } else {
        inputRef.current.classList.add('autocomplete-submitting');
      }
      inputRef.current.classList.remove('autocomplete-error');
      inputRef.current.classList.remove('autocomplete-noerror');
    }
  }, [values, isSubmitting]);

  useEffect(() => {
    inputRef.current.addEventListener('focusout', function () {
      setFieldTouched('address');
    });
  }, [setFieldTouched]);

  useEffect(() => {
    if (values.address) {
      if (values.streetNumber !== null && values.streetNumber !== undefined) {
        inputRef.current.value = `${values.streetNumber} ${values.streetName}, ${values.city}`;
      } else {
        inputRef.current.value = `${values.streetName}, ${values.city}`;
      }
    }
  });

  return (
    <div className="autocomplete-container">
      <input
        className="autocomplete-input autocomplete-noerror"
        onChange={handlePlaceChange}
        name="address"
        ref={inputRef}
        placeholder="Adresse*"
      />
    </div>
  );
};

const PlaceAutocomplete = ({ setSelectedPlace, handlePlaceChange }) => {
  return (
    <APIProvider apiKey={API_KEY} region={'fr'} language={'fr'}>
      <Autocomplete onPlaceSelect={setSelectedPlace} handlePlaceChange={handlePlaceChange} />
    </APIProvider>
  );
};

export default PlaceAutocomplete;
