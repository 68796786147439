import { useEffect, useState } from 'react';
import { FormikStepper } from './formikStepper';
import { deviceStepInitialValues } from './step2Device/generic';
import Step3Linky, { linkyStepInitialValues, linkyStepValidation } from './step3Linky';
import Step4PRM, { prmStepInitialValues, prmValidationSchema } from './step4PRM';
import MessageContinue from './messageContinue';
import MessageGoodbye from './messageGoodbye';
import { useTryRegisterSite } from '../util/registerSite';

const Registration2 = ({
  serverUrl,
  providerSlug,
  providerName,
  includePRM,
  step2Device,
  pilotage_system,
  firebase_user_access,
  supportEmail
}) => {
  const [formikErrors, setFormikErrors] = useState({});
  const [formikTouched, setFormikTouched] = useState({});
  const [prmFound, setPrmFound] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(null);

  // Initialize step labels based on the presence of step2Device and prmFound state
  const initializeStepLabels = () => {
    let labels = [{ name: 'Informations', index: 0 }];
    if (step2Device !== null) {
      labels.push({ name: 'Appareil', index: 1 });
    }
    labels.push({ name: 'Linky', index: step2Device !== null ? 2 : 1 });
    return labels;
  };

  const [stepLabels, setStepLabels] = useState(initializeStepLabels());

  const extraStep = 'Numéro de compteur';

  const { tryRegisterSite } = useTryRegisterSite();

  const updateStepLabels = () => {
    if (prmFound === false) {
      if (stepLabels[stepLabels.length - 1].name !== extraStep) {
        // Add extra step with dynamic index
        const newIndex = stepLabels.length;
        setStepLabels((prevLabels) => [
          ...prevLabels.filter((label) => label.name !== extraStep), // Remove if already exists
          { name: extraStep, index: newIndex } // Add with the next available index
        ]);
      }
    } else {
      setStepLabels((prevLabels) => prevLabels.filter((label) => label.name !== extraStep));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateStepLabels, [prmFound, stepLabels.length]);

  const handleSubmit = async (values) => {
    try {
      const { isSuccess } = await tryRegisterSite(
        serverUrl,
        providerName,
        values,
        prmFound,
        pilotage_system,
        firebase_user_access
      );
      if (prmFound === undefined) {
        setPrmFound(isSuccess);
      }
      if (!isSuccess) {
        setErrorMessage('Une erreur inattendue s’est produite. Veuillez réessayer ultérieurement.');
      }
    } catch (error) {
      if (error.response.data.message === 'PRM Duplicate') {
        setErrorMessage(
          <>
            Ce PRM est déjà enregistré. Veuillez vérifier les informations saisies ou contacter le
            support à l'adresse <a href={`mailto:${supportEmail}`}>{supportEmail}</a> pour obtenir
            de l'aide.
          </>
        );
      } else {
        setErrorMessage('Une erreur inattendue s’est produite. Veuillez réessayer ultérieurement.');
      }
      throw error;
    }
  };

  return (
    <FormikStepper
      stepLabels={stepLabels}
      initialValues={{
        ...linkyStepInitialValues,
        ...(step2Device ? deviceStepInitialValues : {}),
        ...prmStepInitialValues
      }}
      setErrorMessage={setErrorMessage}
      onSubmit={handleSubmit}
      setFormikErrors={setFormikErrors}
      setFormikTouched={setFormikTouched}
    >
      <MessageContinue includeDevice={step2Device !== null} />
      {step2Device}
      <Step3Linky
        formikErrors={formikErrors}
        formikTouched={formikTouched}
        validationSchema={linkyStepValidation}
        providerSlug={providerSlug}
        includePRM={includePRM}
        errorMessage={errorMessage}
      />
      {prmFound === false && (
        <Step4PRM errorMessage={errorMessage} validationSchema={prmValidationSchema} />
      )}
      <MessageGoodbye />
    </FormikStepper>
  );
};

export default Registration2;
