import React, { useState } from 'react';
import { Grid, Typography, Box, Stack } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

interface DeviceCounterProps {
  displayName: string;
  deviceName: string;
  iconUrl: string;
  setDevicesPresent: (deviceName: string, devicePresent: boolean) => void;
}

const DeviceCounter: React.FC<DeviceCounterProps> = ({
  displayName,
  deviceName,
  iconUrl,
  setDevicesPresent
}) => {
  const [checked, setChecked] = useState(false);
  const handleClick = () => {
    setDevicesPresent(deviceName, !checked);
    setChecked(!checked);
  };
  return (
    <Grid
      onClick={handleClick}
      container
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      style={{
        width: '160px',
        height: '160px', // Fixed height for consistent size
        borderRadius: '8px',
        padding: '14px',
        margin: '0 auto',
        outline: checked ? '2px solid #002160' : '1px solid black',
        backgroundColor: checked ? '#E7F0FE' : '#FFFFFF',
        cursor: 'pointer',
        transition: 'outline 0.05s ease, background-color 0.05s ease'
      }}
    >
      <Box
        sx={{
          height: '0px',
          width: '100%'
        }}
      >
        {checked ? (
          <CheckCircleIcon
            style={{ marginLeft: 'auto', transition: 'opacity 0.5s ease-in-out', color: '#002160' }}
          />
        ) : (
          <RadioButtonUncheckedIcon
            style={{ marginLeft: 'auto', transition: 'opacity 0.5s ease-in-out', color: '#002160' }}
          />
        )}
      </Box>
      <Grid
        item
        style={{
          display: 'flex',
          width: '100%', // Ensures the item takes the full width of the parent
          justifyContent: 'center'
        }}
      >
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Box
            component="img"
            src={iconUrl}
            alt={deviceName}
            sx={{
              width: '40px',
              height: '40px',
              objectFit: 'cover'
            }}
          />
        </Stack>
      </Grid>
      <Grid
        item
        style={{
          display: 'flex',
          width: '100%', // Ensures the item takes the full width of the parent
          justifyContent: 'center',
          alignItems: 'center' // Centers content vertically
        }}
      >
        <Typography
          variant="h6"
          component="div"
          style={{
            fontSize: '16px',
            textAlign: 'center'
          }}
        >
          {displayName}
        </Typography>
      </Grid>
      <Box
        sx={{
          height: '0px',
          width: '20px'
        }}
      />
    </Grid>
  );
};

export default DeviceCounter;
