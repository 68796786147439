import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBU0NCYWamVPT33v57ALsfCDhrdc8FJvRU',
  authDomain: 'tilt-prod-69314.firebaseapp.com',
  projectId: 'tilt-prod',
  storageBucket: 'tilt-prod.appspot.com',
  messagingSenderId: '598617646676',
  appId: '1:598617646676:web:442af09d3e3b6cb42aefc1'
};

const app = initializeApp(firebaseConfig);
const appAuth = getAuth(app);
export default appAuth;
