import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Field } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import * as Yup from 'yup';
import DeviceCounter from './deviceCounter';
import { CheckboxError } from '../util/checkbox';
import { required_message } from '../util/fields';
import ErrorMessage from '../util/errorMessage';
import { useFormikContext } from 'formik';
import { useState, useEffect } from 'react';

export const infoStepValidation = Yup.object({
  email: Yup.string().required(`${required_message} Email`).email("Format d'email invalide"),
  acceptedConditions: Yup.bool().oneOf([true], 'Vous devez accepter les conditions'),
  atLeastOneDevicePresent: Yup.bool().oneOf([true], 'Vous devez ajouter au moins un appareil'),
  electricRadiatorPresent: Yup.bool(),
  gasOrOilBoilerPresent: Yup.bool(),
  heatPumpPresent: Yup.bool()
});

const Step1Info = ({
  formikErrors,
  formikTouched,
  errorMessage,
  validationSchema,
  providerSlug
}) => {
  const acceptLabel = (
    <p>
      J’accepte{' '}
      <a href={`/terms/${providerSlug}`} target="_blank" rel="noopener noreferrer">
        les conditions de l’utilisation
      </a>{' '}
      de ce service et la politique de confidentialité qui s’y rattache. J’atteste avoir lu et
      compris.
    </p>
  );

  const { setFieldValue, setFieldTouched, values, errors, touched } = useFormikContext();
  const [errorMessageDevices, setErrorMessageDevices] = useState(null);
  const handleSetDevicesPresent = (deviceName, isPresent) => {
    setFieldValue(deviceName, isPresent);
    setFieldTouched(deviceName, true);
  };
  useEffect(() => {
    let atLeastOneDevicePresent = false;
    if (
      values.electricRadiatorPresent === true ||
      values.gasOrOilBoilerPresent === true ||
      values.heatPumpPresent === true
    ) {
      atLeastOneDevicePresent = true;
    }
    setFieldValue('atLeastOneDevicePresent', atLeastOneDevicePresent);
    if (values.atLeastOneDevicePresent === false && values.acceptedConditions && values.email) {
      setErrorMessageDevices('Vous devez ajouter au moins un appareil ci-dessus.');
    } else {
      setErrorMessageDevices(null);
    }
  }, [setFieldValue, values]);
  return (
    <>
      <Typography variant="h4" gutterBottom align={'left'} paddingLeft={1}>
        <b>Vos informations </b>
      </Typography>
      <Stack paddingBottom={2} paddingTop={0} direction="row">
        <Typography gutterBottom align={'left'} paddingLeft={1}>
          Veuillez sélectionner les appareils de chauffe présents dans votre logement&nbsp;:
        </Typography>
      </Stack>
      <Stack paddingBottom={2} direction="row" justifyContent="center">
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>
            <DeviceCounter
              deviceName="electricRadiatorPresent"
              displayName="Radiateur électrique (sauf sèche-serviette)"
              iconUrl="/heater.png"
              setDevicesPresent={handleSetDevicesPresent}
            />
          </Grid>
          <Grid item>
            <DeviceCounter
              deviceName="gasOrOilBoilerPresent"
              displayName="Chaudière à gaz ou au fioul"
              iconUrl="/electric-boiler.png"
              setDevicesPresent={handleSetDevicesPresent}
            />
          </Grid>
          <Grid item>
            <DeviceCounter
              deviceName="heatPumpPresent"
              displayName="Pompe à chaleur ou chaudière électrique"
              iconUrl="/heat-pump.png"
              setDevicesPresent={handleSetDevicesPresent}
            />
          </Grid>
        </Grid>
      </Stack>
      <Stack paddingBottom={2} direction="row" justifyContent="center">
        {errorMessageDevices && (
          <Typography color="error" variant="caption" marginTop={'3px !important'}>
            {errorMessageDevices}
          </Typography>
        )}
      </Stack>
      <Stack paddingBottom={0} paddingTop={3} direction="row">
        <Field fullWidth name="email" component={TextField} label="Email*" />
      </Stack>
      <Box paddingBottom={2} paddingLeft={'10px'}>
        <Field
          name="acceptedConditions"
          type="checkbox"
          component={CheckboxWithLabel}
          Label={{ label: acceptLabel }}
        />
        {errors.acceptedConditions && touched.acceptedConditions && (
          <CheckboxError>{errors.acceptedConditions}</CheckboxError>
        )}
      </Box>

      {errorMessage && (
        <div style={{ marginTop: '15px' }}>
          <ErrorMessage errorMessage={errorMessage} />
        </div>
      )}
    </>
  );
};

export default Step1Info;
