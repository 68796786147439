import { Box, Typography } from '@mui/material';

const MessageWelcome = ({ includeDevice }) => {
  return (
    <Box maxWidth="460px" margin="0 auto">
      <Typography variant="h4" align="left" paddingLeft={1}>
        <b>C'est parti pour l'enregistrement</b>
      </Typography>
      <Typography variant="body1" align="left" paddingLeft={1} paddingBottom={0} paddingTop={1.5}>
        Cela ne prendra que 3 minutes.
      </Typography>
      <Box paddingTop={2} paddingBottom={1} align="left">
        <ol>
          <li>Recupérer vos informations</li>
          {includeDevice && (
            <>
              <br />
              <li>Connecter votre appareil</li>
            </>
          )}
          <br />
          <li>Connecter votre compteur Linky.</li>
        </ol>
      </Box>
    </Box>
  );
};

export default MessageWelcome;
