import { useState } from 'react';
import axios from 'axios';
import { FormikStepper } from './formikStepper';
import MessageWelcome from './messageWelcome';
import Step1Info, { infoStepValidation } from './step1Info';
import MessageVerifyEmail from './messageVerifyEmail';
import MessageIneligible from './messageIneligible';
import { useLocation } from 'react-router-dom';

const Registration1 = ({
  serverUrl,
  emailLogoSrc,
  providerSlug,
  providerName,
  providerDomain,
  supportEmail,
  includeDevice
}) => {
  const [formikErrors, setFormikErrors] = useState({});
  const [formikTouched, setFormikTouched] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const installerId = query.get('installerid');

  const stepLabels = [
    { name: 'Informations', index: 1 },
    includeDevice ? { name: 'Appareil', index: 11 } : null,
    { name: 'Linky', index: 12 }
  ].filter(Boolean); // Filter out null values

  return (
    <FormikStepper
      stepLabels={stepLabels}
      setErrorMessage={setErrorMessage}
      initialValues={{
        email: '',
        acceptedConditions: false,
        atLeastOneDevicePresent: false,
        electricRadiatorPresent: false,
        gasOrOilBoilerPresent: false,
        heatPumpPresent: false
      }}
      onSubmit={async (values) => {
        try {
          localStorage.setItem('emailForAuth', values.email);
          await axios.post(`${serverUrl}/verify_user_email`, null, {
            params: {
              user_email: values.email,
              provider_slug: providerSlug,
              provider_name: providerName,
              provider_domain: providerDomain,
              logo_url: `https://remote.tilt-energy.com${emailLogoSrc}`,
              reply_to_email: supportEmail,
              installer_id: installerId,
              electric_radiator_present: values.electricRadiatorPresent,
              heat_pump_present: values.heatPumpPresent
            }
          });
        } catch (error) {
          setErrorMessage(
            'Une erreur inattendue s’est produite. Veuillez réessayer ultérieurement.'
          );
          throw error;
        }
      }}
      setFormikErrors={setFormikErrors}
      setFormikTouched={setFormikTouched}
    >
      <MessageWelcome includeDevice={includeDevice} />
      <Step1Info
        formikErrors={formikErrors}
        formikTouched={formikTouched}
        errorMessage={errorMessage}
        validationSchema={infoStepValidation}
        providerSlug={providerSlug}
      />
      <MessageIneligible removeStepper={true} />
      <MessageVerifyEmail supportEmail={supportEmail} />
    </FormikStepper>
  );
};

export default Registration1;
