import React, { useState, useEffect } from 'react';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { CircularProgress, Grid, Step, StepLabel, Stepper } from '@mui/material';
import { RawCaseButton } from '../util/buttons';

export interface StepName {
  name: string;
  index: number;
}

export interface FormikStepperProps extends FormikConfig<FormikValues> {
  children: React.ReactElement[];
  stepLabels: StepName[];
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
  setFormikErrors: React.Dispatch<React.SetStateAction<object>>;
  setFormikTouched: React.Dispatch<React.SetStateAction<object>>;
}

/**
 * Multi-step form that displays step tracker, back and continue buttons.
 *
 * Form validation:
 *  * Only the validationSchema of the current child is used on a given step.
 *
 * Form submission:
 *  * This component assumes there is a final step to display a message, so it submits the form on
 *  the second to last step.
 *
 * Step tracker:
 *  * The top step tracker is completely customizable through `stepLabels` prop.
 *  * The internal 'step' index starts from 0 so if you want to show steps completed previously (outside of this instance)
 *  then you can set the index of the `StepName` to a negative number.
 *  * Similarly, if you have a step that you don't want to display in the tracker (for example, a welcome message),
 *  just avoid using a given index value in your `StepName`.
 *  * Finally, if there are steps that will be completed in the future, not in this instance, you can set index values
 *  higher than the number of your steps.
 */
export function FormikStepper({
  children,
  stepLabels,
  setErrorMessage,
  setFormikErrors,
  setFormikTouched,
  ...props
}: FormikStepperProps) {
  const allChildrenArray = React.Children.toArray(children) as React.ReactElement[];
  const [step, setStep] = useState(0);
  const currentChild = allChildrenArray[step];

  function activeStep() {
    return stepLabels.findIndex((item) => item.index === step);
  }

  function isLastRegistrationStep() {
    return step === allChildrenArray.length - 2;
  }

  function isFinalStep() {
    return step === allChildrenArray.length - 1;
  }

  // At this step the stepper and buttons need to be removed
  function isIneligibleStep() {
    return currentChild.props.removeStepper === true;
  }

  useEffect(() => {
    setErrorMessage(null); // Clear error message
  }, [step, setErrorMessage]);

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastRegistrationStep()) {
          await props.onSubmit(values, helpers);
          setStep((s) => s + 1);

          // If setTouched is populated from a previous step, it might trigger displaying errors because of empty fields
          //  even though the user hasn't touched the fields in the next step.
          await helpers.setTouched({});
        } else if (isFinalStep()) {
          // TODO: Redirect to main page
          console.log('Redirect to main page');
        } else {
          // this applies only for registration1 component
          if (values.atLeastOneDevicePresent === true) {
            let requiredDevices = ['electricRadiatorPresent', 'heatPumpPresent'];
            let isEligible = false;
            requiredDevices.forEach((device) => {
              if (values[device] === true) {
                isEligible = true;
              }
            });
            let ineligibleDevices = ['gasOrOilBoilerPresent'];
            ineligibleDevices.forEach((device) => {
              if (values[device] === true) {
                isEligible = false;
              }
            });
            if (isEligible === false && step > 0) {
              await helpers.setTouched({});
            } else if (step > 0) {
              await props.onSubmit(values, helpers);
              setStep((s) => s + 1);
              await helpers.setTouched({});
            }
          }
          // this applies only for registration2 component
          if (!values.devices) {
            setStep((s) => s + 1);
          }

          await helpers.setTouched({});
        }
      }}
    >
      {({ errors, touched, isSubmitting, submitForm }) => {
        setFormikErrors(errors);
        setFormikTouched(touched);

        return (
          <Form autoComplete="on">
            {isFinalStep() || isIneligibleStep() ? null : (
              <Stepper alternativeLabel activeStep={activeStep()} sx={{ paddingBottom: 5 }}>
                {stepLabels.map((child) => (
                  <Step key={child.name} completed={activeStep() > child.index}>
                    <StepLabel>{child.name}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}

            {currentChild}
            <br />
            <br />
            <Grid container spacing={6} justifyContent="space-between">
              {step > 0 && !isFinalStep() && !isIneligibleStep() ? (
                <Grid item>
                  <RawCaseButton
                    disabled={isSubmitting}
                    variant="outlined"
                    color="primary"
                    onClick={() => setStep((s) => s - 1)}
                  >
                    <b>Retour</b>
                  </RawCaseButton>
                </Grid>
              ) : (
                <div style={{ flex: 1 }}></div>
              )}
              {isFinalStep() || isIneligibleStep() ? (
                <div style={{ flex: 1, height: '50px' }}></div>
              ) : (
                <Grid item>
                  <RawCaseButton
                    startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    onSubmit={submitForm}
                    type="submit"
                  >
                    <b>Continuer</b>
                  </RawCaseButton>
                </Grid>
              )}
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
