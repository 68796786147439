import '../css/findPRMTutorial.css';
import HelpIcon from '@mui/icons-material/Help';

const FindPRMTutorial = () => {
  return (
    <div className="find-prm-tutorial">
      <div className="inner">
        <h1>
          <HelpIcon style={{ fontSize: '40px', marginBottom: '6px' }} />
          <br />
          Comment trouver mon
          <br />
          numéro PRM (PDL) ?
        </h1>

        <h2>Méthode 1 : Sur votre compteur Linky</h2>
        <ol>
          <li>Accédez à l'écran de votre compteur.</li>
          <li>Appuyez successivement sur le bouton "+" jusqu'à l'affichage de "numéro de PRM".</li>
          <li>
            Notez le numéro à 14 chiffres qui s'affiche. Assurez-vous de ne pas le confondre avec le
            numéro de série, qui se trouve sur le boîtier au-dessus de l'écran.
          </li>
        </ol>

        <img
          src="/linky_prm.png"
          alt="Affichage du numéro PRM sur un compteur Linky"
          style={{
            width: '100%',
            maxWidth: '220px',
            margin: '13px auto 48px auto',
            display: 'block',
            borderRadius: '7px'
          }}
        ></img>

        <h2>Méthode 2 : Sur votre facture d'électricité</h2>
        <ol>
          <li>Localisez la section dédiée à votre Point de livraison (PDL) sur votre facture.</li>
          <li>Reportez le numéro à 14 chiffres indiqué.</li>
        </ol>
      </div>
    </div>
  );
};

export default FindPRMTutorial;
