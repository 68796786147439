import { Paper, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const DisclaimerMessage = () => {
  return (
    <Paper
      elevation={0}
      variant={'outlined'}
      style={{
        padding: '16px 16px 16px 16px',
        margin: '10px 0px 10px 0px',
        textAlign: 'left',
        backgroundColor: 'rgb(252 252 252)',
        color: '#363635'
      }}
    >
      <Typography variant="p" lineHeight={1.5}>
        <InfoIcon style={{ verticalAlign: '-5px', marginRight: '5px', fontSize: '24px' }} />
        Pour mener à bien le programme, nous utiliserons les données de température, de consommation
        et de pilotage de vos appareils connectés.
      </Typography>
    </Paper>
  );
};

export default DisclaimerMessage;
