import { Box, Typography, Paper } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import ErrorMessage from '../util/errorMessage';
import * as Yup from 'yup';

export const prmStepInitialValues = {
  prm: ''
};

export const prmValidationSchema = Yup.object({
  prm: Yup.string()
    .matches(/^\d{14}$/, 'Le numéro PRM contient exactement 14 chiffres')
    .required('Required')
});
const Step4PRM = ({ errorMessage }) => {
  return (
    <>
      <Box paddingBottom={3}>
        <Typography variant="h5" align={'left'} paddingLeft={1}>
          <b>Oups! Nous n'avons pas trouvé votre compteur Linky</b>
        </Typography>

        <Typography variant="body1" align={'left'} paddingLeft={1} paddingTop={2}>
          Merci de nous communiquer le numéro de votre compteur.
        </Typography>

        <Box display="flex" justifyContent="center" width="100%" paddingTop={2} paddingBottom={3.5}>
          <Field
            name="prm"
            component={TextField}
            label="Numéro PRM*"
            style={{ width: '100%', maxWidth: '260px' }}
            autoFocus
          />
        </Box>
      </Box>

      <Paper
        elevation={0}
        variant={'outlined'}
        style={{
          padding: '16px',
          textAlign: 'left',
          backgroundColor: 'rgb(252 252 252)',
          color: '#363635'
        }}
      >
        <Typography
          variant="h6"
          align={'left'}
          paddingTop={1.5}
          paddingBottom={3.5}
          fontWeight={'600'}
          lineHeight={'27px'}
          textAlign={'center'}
        >
          <HelpIcon style={{ verticalAlign: 'sub', paddingRight: '5.5px', fontSize: '25px' }} />
          Comment trouver mon numéro de compteur ?
        </Typography>

        <Typography variant="h7" align={'left'} style={{ fontWeight: '600' }}>
          Méthode 1 : Sur votre compteur Linky
        </Typography>
        <Box paddingTop={0} paddingBottom={5.5} align={'left'} style={{ fontSize: '15.5px' }}>
          <ol>
            <li style={{ marginBottom: '7px' }}>Accédez à l'écran de votre compteur.</li>
            <li style={{ marginBottom: '7px' }}>
              Appuyez successivement sur le bouton "+" jusqu'à l'affichage de "numéro de PRM".
            </li>
            <li>
              Notez le numéro à 14 chiffres qui s'affiche. Assurez-vous de ne pas le confondre avec
              le numéro de série, qui se trouve sur le boîtier au-dessus de l'écran.
            </li>
          </ol>

          <img
            src="/linky_prm.png"
            alt="Affichage du numéro PRM sur un compteur Linky"
            style={{
              width: '100%',
              maxWidth: '220px',
              margin: '0 auto',
              display: 'block',
              borderRadius: '7px'
            }}
          ></img>
        </Box>

        <Typography variant="h7" align={'left'} style={{ fontWeight: '600' }}>
          Méthode 2 : Sur votre facture d'électricité
        </Typography>
        <Box align={'left'}>
          <ol>
            <li style={{ marginBottom: '7px', fontSize: '15.5px' }}>
              Localisez la section dédiée à votre Point de livraison (PDL) sur votre facture.
            </li>
            <li>Reportez le numéro à 14 chiffres indiqué.</li>
          </ol>
        </Box>
      </Paper>

      {errorMessage && (
        <div style={{ marginTop: '14px' }}>
          <ErrorMessage errorMessage={errorMessage} />
        </div>
      )}
    </>
  );
};

export default Step4PRM;
