import '../css/terms.css';

const PrivacyPolicy = () => {
  return (
    <div className="terms-and-conditions">
      <div className="inner">
        <h1>POLITIQUE DE CONFIDENTIALITE</h1>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
