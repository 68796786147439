import { grey } from '@mui/material/colors';
import { Box, Card, CardContent, Stack } from '@mui/material';

const TiltCenterCard = ({ children, logoChild, poweredBy }) => {
  return (
    <Stack
      spacing={2}
      style={{ alignItems: 'center', justifyContent: 'center', marginBottom: '40px' }}
    >
      {logoChild}
      <Box
        sx={{
          width: { sm: '60ch', xs: '90%' },
          '& .MuiTextField-root': { margin: 1 }
        }}
      >
        <Card
          sx={{
            border: 1,
            borderColor: grey[400],
            borderRadius: 5,
            backgroundColor: '#fffffe',
            padding: 2,
            paddingBottom: 0
          }}
        >
          <CardContent>{children}</CardContent>
        </Card>
        <br />

        {poweredBy && (
          <>
            <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
              <img src="/powered_by_text.png" alt="Powered by" style={{ height: '18px' }} />
              <img src="/logo_blue_nobckng.png" alt="Logo" style={{ height: '38px' }} />
            </Stack>
            <br />
          </>
        )}
      </Box>
    </Stack>
  );
};
export default TiltCenterCard;
