import '../css/terms.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <div className="inner">
        <h1>CONDITIONS GÉNÉRALES D’UTILISATION</h1>
        <p>
          GRIDFIT, entreprise basée en France fournit une solution logicielle permettant aux
          systèmes de pilotage à distance d’équipements électriques la participation à la
          modulation/effacement en France.
        </p>
        <p>Cette solution est mise à disposition des Utilisateurs gratuitement.</p>

        <h2 style={{ marginBottom: '21px' }}>Définitions</h2>
        <ul>
          <li>
            <strong>Effacement résidentiel</strong> (ou effacement diffus) : désigne le fait de
            réduire temporairement la consommation d’électricité d’un grand nombre de logements. Il
            s’agit d’interrompre brièvement, mais de façon coordonnée, l’alimentation d’équipements
            connectés à l’intérieur de ces logements afin de réduire la demande totale d’une région
            ou du pays, sans pour autant avoir un impact sur le confort des occupants.
          </li>
          <li>
            <strong>Flexibilité</strong> : capacité d’un Utilisateur à moduler ponctuellement sa
            consommation électrique.
          </li>
          <li>
            <strong>Marchés ouverts à la valorisation des Effacements de consommation</strong> :
            correspondent aux mécanismes d’effacement de consommation d’électricité et de capacité
            prévus au titre du Code de l’Énergie (notamment au livre II, dans un titre VII consacré
            à l'effacement de consommation).
          </li>
          <li>
            <strong>Matériel(s)</strong> : ensemble des matériaux nécessaires au pilotage à distance
            de ses consommations électriques par l’Utilisateur et destinés à permettre, à terme, de
            prétendre à la Modulation de consommations.
          </li>
          <li>
            <strong>Modulation</strong> : désigne le fait de modifier temporairement la puissance
            soutirée du réseau par le Site de l’Utilisateur en décalant la plage de fonctionnement
            des équipements de consommation.
          </li>
          <li>
            <strong>Service</strong> : désigne le service d’effacement proposé par GRIDFIT, ainsi
            que la plateforme mise à disposition au client lui permettant de suivre les effacements
            réalisés.
          </li>
          <li>
            <strong>Site de l’Utilisateur</strong> : désigne le site de consommation pour lequel
            l’Utilisateur est titulaire du Contrat d’Accès au Réseau.
          </li>
          <li>
            <strong>Utilisateur</strong> : désigne toute personne répondant aux conditions
            d’éligibilité du Service et qui utilise le Service.
          </li>
        </ul>

        <h2>Objet</h2>
        <p>
          Les présentes conditions générales d’utilisation s’appliquent aux relations entre GRIDFIT
          et tout Utilisateur.
        </p>
        <p>
          GRIDFIT se réserve le droit de modifier à tout moment les présentes conditions générales
          d’utilisation pour tenir compte notamment d’éventuelles évolutions réglementaires ou
          techniques. Dans ce cas, toute modification sera portée à la connaissance de l’Utilisateur
          par tout moyen. Ce dernier disposera, à compter de cette notification, d’un délai d’un (1)
          mois pour manifester son refus, par écrit, qui vaudra résiliation des Services. A défaut,
          l’Utilisateur sera réputé avoir accepté les nouvelles conditions générales d’utilisation,
          qui s’appliqueront de plein droit à compter de leur entrée en vigueur.
        </p>

        <h2>Le Service proposé par GRIDFIT</h2>
        <h3>1. Généralités</h3>
        <p>
          L’Utilisateur s’engage à prendre connaissance des présentes conditions générales
          d’utilisation avant la mise en Service du programme d’effacement afin de permettre une
          exploitation sans erreur.
        </p>

        <h3>2. Conditions d’accès à la solution</h3>
        <p>
          2.1. L’Utilisateur autorise GRIDFIT à faire participer son Site aux Marchés ouverts à la
          valorisation des Effacements de consommation intégrant le mécanisme NEBEF, au Mécanisme
          d’Ajustement et aux Services systèmes. En outre, l’Utilisateur déclare ne pas être engagé
          avec un autre Opérateur d’Effacement, Acteur d’Ajustement, Responsable de Réserve, ou avec
          son fournisseur d’électricité via une offre d’effacement indissociable de la fourniture.
        </p>
        <p>
          2.2. L’Utilisateur autorise GRIDFIT à contrôler ses équipements pour réaliser des
          Modulations, et à les valoriser sur les différents mécanismes mentionnés précédemment.
        </p>
        <p>
          2.3. L’Utilisateur accepte que GRIDFIT collecte ses données de consommation et donne son
          accord pour la transmission des différentes informations commercialement sensibles ou des
          informations, notamment commerciales, nécessaires à la bonne exécution du mécanisme à RTE
          ou au Gestionnaire de Réseau de Distribution auquel il est rattaché, qui incluent les
          données de consommations d’électricité ainsi que les aspects contractuels de l’offre de
          fourniture d’électricité.
        </p>
        <p>
          2.4. L’Utilisateur autorise RTE à réaliser des audits des systèmes de mesure et de
          transmission mis en place par GRIDFIT, ainsi qu’à contrôler la chaîne de commande des
          effacements mis en place par GRIDFIT, y compris lors d’expérimentations.
        </p>
        <p>
          2.5. L’Utilisateur s’engage à participer au Mécanisme de Capacité au sein d’entités de
          certification certifiées par GRIDFIT. Il s’engage à avoir pris connaissance des
          engagements et responsabilités associés au Contrat de certification mis à disposition sur
          le site de RTE.
        </p>
        <p>
          2.6. L’Utilisateur déclare sur l’honneur que la réalisation d’Effacement de consommation
          n’implique pas l’utilisation de combustibles fossiles.
        </p>
        <p>
          2.7. L’Utilisateur s’engage à participer à l’Appel d’Offres Flexibilités Décarbonées avec
          GRIDFIT.
        </p>
        <p>
          2.8. L’Utilisateur s’engage à informer GRIDFIT de tout déménagement, changement de
          situation, ajout de nouvelle solution connectée et dysfonctionnement des solutions
          connectées ayant un impact sur le bon fonctionnement du Service, dans les meilleurs
          délais, à l’adresse e-mail :{' '}
          <a href="mailto:contact@tilt-energy.com">contact@tilt-energy.com</a>.
        </p>
        <p>
          2.9. L’Utilisateur peut à tout moment, sans que cela constitue une décision de
          résiliation, suspendre toute Modulation intervenant ou susceptible d’intervenir pendant
          une période donnée.
        </p>
        <p>
          2.10. L’Utilisateur reconnait le droit pour GRIDFIT de refuser ou résilier une adhésion
          s’il ne rempli par les conditions d’éligibilité. Et ce, sans que l’Utilisateur ne puisse
          prétendre à une quelconque indemnisation à quelque titre que ce soit.
        </p>
        <p>
          2.11. L’Utilisateur renonce au profit de GRIDFIT ou de ses partenaires, en échange de la
          fourniture des services associés et de l'exploitation du boîtier par GRIDFIT ou ses
          partenaires, aux droits liés à la valorisation de l'effacement de sa consommation et des
          services associés, qui pourraient être délivrés en relation avec GRIDFIT ou ses
          partenaires.
        </p>

        <h3>3. Responsabilité</h3>
        <p>
          L’Utilisateur reconnait que pèse sur GRIDFIT une obligation de moyen dans le but
          d’atteindre l’objectif des présentes conditions générales d’utilisation. Néanmoins,
          GRIDFIT ne garantit pas :
        </p>
        <ul>
          <li>La compatibilité du matériel avec la plateforme mise à disposition&nbsp;;</li>
          <li>La disponibilité en permanence, sans interruption et exempt d’erreurs&nbsp;;</li>
          <li>
            Un niveau de performance spécifique ou l’adaptation aux besoins de l’Utilisateur ;
          </li>
          <li>L’absence de perturbation, intrusion, piratage ou virus.</li>
        </ul>

        <p>
          L’Utilisateur reconnait que GRIDFIT n’est responsable que des dommages directs qui
          pourraient être causé par l’utilisation du Service. En aucun cas, GRIDFIT ne pourrait être
          tenu pour responsable des dommages indirects ou du fait d’un tiers.
        </p>
        <p>
          L’Utilisateur reconnait que GRIDFIT ne peut être tenu pour responsable en cas de
          dysfonctionnement, suspension ou résiliation des du Service par le fournisseur du service
          de pilotage de la solution connectée.
        </p>

        <h3>4. Durée et résiliation</h3>
        <p>
          Les conditions générales d’utilisation prennent effet à compter de la signature de
          l’Utilisateur. Elles sont conclues pour une durée indéterminée, avec possibilité de
          résilier à tout moment dans les conditions ci-après.
          <br />
          L’Utilisateur peut résilier unilatéralement, sans frais, après notification à l’adresse
          e-mail : <a href="mailto:contact@tilt-energy.com">contact@tilt-energy.com</a>. La
          résiliation sera effective sous un délai d’un (1) mois à compter de la réception de la
          notification de résiliation par les services de GRIDFIT. Durant le préavis de résiliation,
          les Parties restent tenues de respecter leurs obligations respectives. A l’issu du délai,
          les Services cesseront.
        </p>

        <p>
          En cas de contrat conclu à distance ou hors établissement, l’Utilisateur dispose d’un
          droit de rétractation de quatorze (14) jours à compter du lendemain de la conclusion des
          présentes conditions générales d’utilisation. Pour exercer le droit de rétractation,
          l’Utilisateur doit le notifier à GRIDFIT les informations suivantes à l’adresse e-mail{' '}
          <a href="mailto:contact@tilt-energy.com">contact@tilt-energy.com</a> :
        </p>
        <ul>
          <li>
            Nom, prénom, adresse postale, numéro de téléphone, adresse électronique, n° PDL, n° de
            compteur Linky&nbsp;;
          </li>
          <li>
            La décision de rétractation au moyen du formulaire type figurant en annexe aux présentes
            conditions générales d’utilisation.
          </li>
        </ul>

        <h2>Les données à caractère personnel</h2>
        <p>
          Afin de répondre au besoin de l’Utilisateur, GRIDFIT peut être amenée à recueillir des
          informations à caractère personnel, traitées par des moyens informatiques. Pendant la
          navigation sur l’Application Web, certaines informations, qui sont marquées d’un
          astérisque, doivent être fournies obligatoirement. Il appartient à l’Utilisateur de
          vérifier que les informations fournies par le biais de l’Application Web sont exactes et
          complètes.
        </p>

        <p>
          La collecte et le traitement de ces données sont nécessaires notamment pour la création du
          compte Utilisateur, pour la remontée des données de consommations électriques, pour
          l’envoi des notifications d’alerte, pour l’activation des programmes de chauffe tels que
          définis par l’Utilisateur, ainsi que pour l’activation de la Flexibilité et la
          participation à la Modulation.
        </p>

        <p>
          Pour le bon suivi des demandes, l’Utilisateur informera immédiatement le service
          assistance de toute modification des informations fournies à l’adresse e-mail :{' '}
          <a href="mailto:contact@tilt-energy.com">contact@tilt-energy.com</a>.
        </p>

        <h3>1. Qualité de responsable de traitement – finalité des traitements</h3>
        <p>
          Dans le cadre de son activité, GRIDFIT agit en qualité de responsable de traitement, et
          procède à un traitement informatisé des données des Utilisateurs dans le respect de la
          réglementation relative à la protection des données personnelles.
        </p>

        <h3>2. Typologie des données traitées</h3>
        <p>
          GRIDFIT collecte, stocke et traite uniquement les données nécessaires à l’exécution du
          Service, à l’amélioration de ses outils de gestion de la flexibilité et à l’administration
          et la gestion de la relation clientèle.
        </p>
        <p>
          En particulier, GRIDFIT traitera notamment les données suivantes, ce que l’Utilisateur
          autorise expressément :
        </p>
        <ul>
          <li>
            Données d’identification : Nom et prénom de l’Utilisateur, adresse, téléphone fixe et /
            ou numéro de téléphone mobile, email, numéro de PDL (Point de livraison), et numéro de
            compteur d’électricité.
          </li>
          <li>
            Données de consommation de l’Utilisateur et de son foyer : consommation totale du
            logement, consommation unitaire d’actifs équipés du Matériel, relevés de consommations
            totales du logement et courbes de charge jusqu’au pas de temps 10 minutes, températures
            mesurées par le Matériel, actions réalisées par l’Utilisateur sur le Matériel
            (instructions, programmes).
          </li>
        </ul>
        <p>
          GRIDFIT est autorisée à accéder auxdites données aux fins notamment de fournir un suivi de
          ses consommations à l’Utilisateur dans l’Application et le Site Web, et de vérifier la
          réalité des activations de Modulation. Tilt sera autorisée à communiquer les éléments à
          RTE (Réseau de Transport Électrique) si requis à cette seule dernière finalité.
        </p>

        <h3>3. Finalités des traitements</h3>
        <p>Les données collectées sont utilisées pour :</p>
        <ul>
          <li>
            la fourniture du Service, pour le pilotage des consommations et la participation à la
            Modulation des consommations&nbsp;;
          </li>
          <li>l’analyse et amélioration de l’utilisation du Service.</li>
        </ul>

        <h3>4. Durée de conservation</h3>
        <p>
          Les données collectées sont conservées uniquement pour la durée nécessaire à la
          réalisation des finalités décrites ci-dessus, dans la limite des délais de prescription en
          vigueur.
        </p>
        <p>
          Les données de consommation et de commande sont conservées jusque trois (3) ans après leur
          agrégation en vue de la fourniture du Service, et jusqu’à un délai glissant de cinq (5)
          ans dans l’unique finalité de pouvoir répondre à ses obligations contractuelles et de
          pouvoir justifier auprès de RTE des données collectées dans la limite du délai dont ce
          dernier dispose pour la vérification des données. Les données collectées seront supprimées
          ou anonymisées à l’issue du Service, ou dans la limite des délais de prescription en
          vigueur, selon les termes et conditions susmentionnés.
        </p>

        <h3>5. Destinations ou catégories de destinataires</h3>
        <p>
          Les données traitées sont destinées aux services internes de GRIDFIT, à ses prestataires
          ou sous-traitants ou aux tiers autorisés en vertu d’une disposition légale ou
          réglementaire.
        </p>
        <p>
          L’attention de l’Adhérent est appelée sur le fait qu’en application des dispositions du
          code de l'énergie (notamment ses articles L. 111-72, L. 111-73, L. 111-73-1 et R. 111-26),
          les gestionnaires de réseaux publics d'électricité (RTE et les GRD) sont tenus de
          préserver strictement la confidentialité des données qu’ils collectent ou qui leur sont
          transmises par GRIDFIT, donc qu'ils ne peuvent utiliser que pour les besoins de
          développement et d’exploitation des réseaux et des systèmes électriques dont ils ont la
          charge, à l'exclusion de tout autre usage, notamment commercial.
        </p>

        <h3>6. Droits des personnes</h3>
        <p>
          L’Utilisateur dispose d’un droit d’accès, de rectification, d’information complémentaire,
          d’opposition, de portabilité, d’effacement et de limitation, dans les conditions prévues
          par la loi Informatique et libertés, auprès du responsable RGPD de GRIDFIT à l’adresse
          e-mail : <a href="mailto:contact@tilt-energy.com">contact@tilt-energy.com</a>.
        </p>

        <h3>
          7. Coordonnées du délégué à la protection des données personnelles et droit d’introduire
          une réclamation auprès d’une autorité de contrôle
        </h3>
        <p>
          L’Utilisateur dispose de la possibilité d’introduire une réclamation auprès de la CNIL et
          de s’adresser au délégué à la protection des données de GRIDFIT. Le délégué à la
          protection des données personnelles peut être contacté à l’adresse e-mail :{' '}
          <a href="mailto:contact@tilt-energy.com">contact@tilt-energy.com</a>.
        </p>

        <h2>Propriété Intellectuelle</h2>
        <p>
          L’utilisateur reconnait la détention de tous les droits de propriété intellectuelle
          relatifs aux Services à GRIDFIT, que ces droits aient été enregistrés ou non. La
          souscription au Service n’entraine en aucun cas le transfert de quelque droit de propriété
          intellectuelle que ce soit au profit de l’Utilisateur.
        </p>
        <p>
          Tous les contenus, dont les textes, images, photographies, infographies, diaporamas, mis à
          disposition de l’Utilisateur dans le cadre du Service, toute marque reproduite en relation
          avec le Service appartienne à GRIDFIT ou ses partenaires et sont protégés par des droits
          de propriété intellectuelle.
        </p>
        <p>
          La violation de ces stipulations est constitutive d’une contrefaçon et expose le
          contrevenant à des poursuites et aux peines pénales et civiles prévues par la loi.{' '}
        </p>

        <h2>Droit applicable</h2>
        <p>
          Les présentes conditions générales d’utilisation sont régies par le droit français, quel
          que soit le lieu d’utilisation.
          <br />
          En cas de contestation, et après toute tentative de recherche d’une solution amiable, les
          tribunaux français seront seuls compétents pour connaître de ce litige.
        </p>

        <h2>Acceptation des CGUs et du transfert des données</h2>
        <p>
          Je, soussigné, accepte les présentes conditions générales d’utilisation et des conditions
          relatives au traitement et transfert des données tels que décrits sous la rubrique « Les
          données à caractère personnel » de ces présentes CGUs.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
