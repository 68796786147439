import axios from 'axios';

function splitAddress(address: string): [string, string] | [null, string] {
  // Splits a given address into two parts: the street number and the rest of the address.
  const match = address.match(/(\d+[\w\s]*?)[\s,]+(.+)/);
  return match ? [match[1], match[2]] : [null, address];
}

export async function isPrmFound(
  serverUrl: string,
  token: string,
  firstName: string,
  lastName: string,
  address: string,
  postcode: string,
  city: string
): Promise<boolean> {
  const [streetNumber, streetName] = splitAddress(address);
  try {
    const response = await axios.post(
      `${serverUrl}/is_prm_found`,
      {
        first_name: firstName,
        last_name: lastName,
        street_number: streetNumber,
        street_name: streetName,
        postcode: postcode,
        city: city
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return false;
  }
}
