import { Box, Typography } from '@mui/material';

const MessageIneligible = () => {
  return (
    <>
      <Box maxWidth="460px" margin="0 auto">
        <img
          src="/failure_emoji.png"
          alt="icon success"
          style={{ height: '55px', marginLeft: '10px' }}
        ></img>

        <Typography variant="h4" align={'left'} paddingLeft={1} paddingTop={2} paddingBottom={0.2}>
          Vous n’êtes pas éligible à ce programme.
        </Typography>
        <Typography
          variant="body1"
          align={'left'}
          paddingLeft={1}
          maxWidth={'300px'}
          paddingTop={1}
          paddingBottom={3.2}
        >
          Merci de votre intérêt pour la transition énergétique.
        </Typography>
      </Box>
    </>
  );
};

export default MessageIneligible;
