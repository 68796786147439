import '../css/findTuyaDeviceIdTutorial.css';
import HelpIcon from '@mui/icons-material/Help';

const FindTuyaDeviceIdTutorial = () => {
  return (
    <div className="find-tuya-deviceid-tutorial">
      <div className="inner">
        <h1>
          <HelpIcon style={{ fontSize: '40px', marginBottom: '6px' }} />
          <br />
          Comment trouver l'ID virtuel de ma passerelle ?
        </h1>

        <h2>
          1. Ouvrez l'application et sélectionnez <u>la passerelle</u>
        </h2>
        <img src="/tuya/device_id_tutorial/step1.png" alt="Etape 1"></img>

        <h2>2. Appuyez sur le crayon</h2>
        <img src="/tuya/device_id_tutorial/step2.png" alt="Etape 2"></img>

        <h2>3. Consultez les "Informations appareil"</h2>
        <img src="/tuya/device_id_tutorial/step3.png" alt="Etape 3"></img>

        <h2>4. Copiez l'ID virtuel qui s'affiche</h2>
        <img src="/tuya/device_id_tutorial/step4.png" alt="Etape 4"></img>
      </div>
    </div>
  );
};

export default FindTuyaDeviceIdTutorial;
