import { Typography } from '@mui/material';
import { ReactNode } from 'react';

interface CheckboxErrorProps {
  children: ReactNode;
}

export function CheckboxError({ children }: CheckboxErrorProps) {
  return (
    <Typography variant="caption" color="error" style={{ paddingLeft: '30px' }}>
      {children}
    </Typography>
  );
}
