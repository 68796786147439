function ProviderLogo({ logoSrc, height, marginTop, marginBottom }) {
  if (!logoSrc) {
    return <div style={{ marginTop: marginTop, marginBottom: marginBottom }}></div>;
  }

  return (
    <div style={{ marginTop: marginTop, marginBottom: marginBottom }}>
      <img src={logoSrc} alt={'Logo'} style={{ height: height }} />
    </div>
  );
}

export default ProviderLogo;
