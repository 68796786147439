import { Box, Typography } from '@mui/material';

const MessageGoodbye = () => {
  return (
    <>
      <Box maxWidth="460px" margin="0 auto">
        <Typography variant="h4" align={'left'} paddingLeft={1} paddingTop={2} paddingBottom={0.2}>
          Bravo !
        </Typography>
        <Typography
          variant="h4"
          align={'left'}
          paddingLeft={1}
          gutterBottom
          fontSize={'28px'}
          fontWeight={'300'}
        >
          Vous voilà inscrit
        </Typography>
        <Typography
          variant="body1"
          align={'left'}
          paddingLeft={1}
          maxWidth={'300px'}
          paddingTop={1}
          paddingBottom={3.2}
        >
          Merci de soutenir la transition énergétique et pour votre confiance.
        </Typography>
        <img
          src="/icon_success.webp"
          alt="icon success"
          style={{ height: '55px', marginLeft: '10px', marginBottom: '-15px' }}
        ></img>
      </Box>
    </>
  );
};

export default MessageGoodbye;
