import { createContext, PropsWithChildren, useRef, useState, useContext, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { CircularProgress, Stack } from '@mui/material';
import {
  onAuthStateChanged,
  isSignInWithEmailLink,
  signInWithEmailLink,
  User
} from 'firebase/auth';
import appAuth from '../initializedFirebase';

type authStatus = 'authenticated' | 'unauthenticated' | 'loading';

const AuthCtx = createContext<{
  verifyEmailLinkAndAuthenticate(): Promise<boolean>;
  signOut(): void;
  status: authStatus;
  user: User | null;
}>({
  status: 'loading',
  async verifyEmailLinkAndAuthenticate() {
    return false;
  },
  signOut() {},
  user: null
});
export const useAuth = () => useContext(AuthCtx);

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const [status, setStatus] = useState<authStatus>('loading');

  const verifyEmailLinkAndAuthenticate = async () => {
    const emailLink = window.location.href;

    try {
      const isValid = isSignInWithEmailLink(appAuth, emailLink);
      if (!isValid) {
        return false;
      }
      let email = localStorage.getItem('emailForAuth') as string;
      if (!email) {
        email = window.prompt('Veuillez fournir votre email pour confirmation') as string;
        localStorage.setItem('emailForAuth', email);
      }
      await signInWithEmailLink(appAuth, email, emailLink);
      setStatus('authenticated');
      localStorage.removeItem('emailForAuth');
      return true;
    } catch (error) {
      console.log(error);
      console.log('Caught error in verifyEmailLinkAndAuthenticate');
      setStatus('unauthenticated');
      return false;
    }
  };
  const signOut = async () => await appAuth.signOut();

  useEffect(() => {
    onAuthStateChanged(appAuth, (user) => {
      if (user) setStatus('authenticated');
      else setStatus('unauthenticated');
    });
  }, [status]);

  return (
    <AuthCtx.Provider
      value={{
        status,
        verifyEmailLinkAndAuthenticate,
        user: appAuth.currentUser,
        signOut
      }}
    >
      {children}
    </AuthCtx.Provider>
  );
};

interface AuthenticationHandlerProps {
  onSuccess: () => void;
  onError: (error: string) => void;
}

const AuthenticationHandler: React.FC<AuthenticationHandlerProps> = ({ onSuccess, onError }) => {
  const [success, setSuccess] = useState<string>('loading');
  const [timeoutOccurred, setTimeoutOccurred] = useState<boolean>(false);
  const { verifyEmailLinkAndAuthenticate, user } = useAuth();
  const { provider } = useParams();
  const isMounted = useRef(true);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isDebug = query.get('debug');

  useEffect(() => {
    const handleVerify = async () => {
      try {
        const token = await user?.getIdToken();

        if (typeof token === 'string' && token.trim() !== '') {
          // User already signed in. Skip verify link
          setSuccess('success');
          onSuccess();
        } else {
          const isSuccess = await verifyEmailLinkAndAuthenticate();
          if (isSuccess) {
            setSuccess('success');
            onSuccess();
          }
        }
      } catch (error) {
        setSuccess('failed');
        onError(`Error during verification: ${error}`);
      }
    };

    // Timeout mechanism
    const timeout = setTimeout(() => {
      if (success !== 'success') {
        setSuccess('failed');
        onError('Authentication timed out');
        setTimeoutOccurred(true);
      }
    }, 10000); // 10 seconds timeout

    // If debug mode is activated, skip authentication
    if (isDebug === 'true') {
      setSuccess('success');
      onSuccess();
    } else {
      // Only verify if we haven't succeeded yet and timeout has not occurred
      if (success !== 'success' && !timeoutOccurred) {
        handleVerify();
      }
    }

    // Cleanup function when component is unmounted
    return () => {
      clearTimeout(timeout);
      isMounted.current = false;
    };
  }, [
    user,
    verifyEmailLinkAndAuthenticate,
    provider,
    isDebug,
    success,
    onSuccess,
    onError,
    timeoutOccurred
  ]);

  return null; // This component doesn't render anything directly
};

interface CompleteAuthProps {
  layout: (component: React.ReactNode) => React.ReactNode;
  continueComponent: React.ReactNode;
}

export const CompleteAuth: React.FC<CompleteAuthProps> = ({ layout, continueComponent }) => {
  const [success, setSuccess] = useState<string>('loading');

  const handleSuccess = () => {
    setSuccess('success');
  };

  const handleError = (error_msg: string) => {
    setSuccess('error');
    console.error(error_msg);
  };

  return (
    <>
      <AuthenticationHandler onSuccess={handleSuccess} onError={handleError} />
      {success === 'success'
        ? layout(continueComponent)
        : success === 'error'
        ? layout(
            <Stack direction="column" alignItems="center">
              <p>
                Oh non! Quelque chose s'est mal passé. Veuillez réessayer ou contactez-nous au
                support@tilt-energy.com
              </p>
              {/*// TODO : button to "send me another email"*/}
            </Stack>
          )
        : layout(
            <Stack direction="column" alignItems="center">
              <h2>Patientez s'il vous plaît ...</h2>
              <CircularProgress />
              <br />
            </Stack>
          )}
    </>
  );
};
