import { Box, Typography } from '@mui/material';

const MessageContinue = ({ includeDevice }) => {
  return (
    <>
      <Box maxWidth="460px" margin="0 auto">
        <Typography variant="h4" align={'left'} paddingLeft={1}>
          <b>Continuer l'enregistrement</b>
        </Typography>
        <Typography variant="body1" align="left" paddingLeft={1} paddingBottom={0} paddingTop={1.5}>
          Cela ne prendra que 2 minutes.
        </Typography>
        <Box paddingTop={2} paddingBottom={2} align={'left'} lineHeight={'23px'}>
          <ol>
            <li>
              Recupérer vos informations: <b>déjà fait</b>
            </li>
            {includeDevice && (
              <>
                <br />
                <li>Connecter votre appareil</li>
              </>
            )}
            <br />
            <li>Connecter votre compteur Linky.</li>
          </ol>
        </Box>
      </Box>
    </>
  );
};

export default MessageContinue;
