import Typography from '@mui/material/Typography';

const ErrorMessage = ({ errorMessage }) => {
  return (
    <Typography
      variant="p"
      sx={{
        color: '#cc0000',
        padding: '13px',
        margin: '0px auto',
        display: 'table',
        textAlign: 'center',
        backgroundColor: '#cc000017',
        borderRadius: '3px',
        lineHeight: '25px'
      }}
    >
      {errorMessage}
    </Typography>
  );
};

export default ErrorMessage;
